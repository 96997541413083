
import React from "react";
import MuiTextInput from "../../../components/MuiComponents/MuiTextInput";
import MuiAutocompleteSelect from "../../../components/MuiComponents/MuiAutocompleteSelect";

interface iFindNumbers1Prop {
    arrayOfCodes: any[]
}
const FindNumbers1 = ({ arrayOfCodes }: iFindNumbers1Prop) => {



    return <>
        <MuiTextInput
            name="numbersAmt"
            type="number"
            outlinedLabel="How many numbers do you want to allocate?"
            required={true} 
            style={{paddingBottom:10}} />
        {/*<MuiCheckbox name="consecutiveNumbers" label="Do you want a consecutive range of numbers? You can not
                  preview the range and must take whatever is given to you."  style={{paddingBottom:10}}  />*/}
                  <div style={{marginBottom:'1em'}}>If you require a consecutive range of numbers then please contact <a href="mailto:support@vipvoip.co.uk">support@vipvoip.co.uk</a>.</div>

        <MuiAutocompleteSelect
            required={true}
            label="Type area code or city name"
            name="areaCode"
            options={arrayOfCodes}
            getOptionLabel={(d) => d.label}
            getOptionSelected={(option, value) => {
                if (option.value === value.value) {
                    return true;
                } else {
                    return false;
                }
            }} />

        {/* <Field name="areaCode" validate={requiredValidator}>
            {({ input, meta }) => (
                <>
                    <SelectAdapter
                        options={arrayOfCodes}
                        placeholder="Type area code or city name"
                        input={input}
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}


z
                </>
            )}
        </Field> */}


    </>
}

export default FindNumbers1;